export default [
  // *** Organizational Structure ***
  {
    addName: "AddOrganizationalStructure",
    editName: "EditOrganizationalStructure",
    list: "OrganizationalStructureList",
    getFormDataPath: "OrganizationalStructure",
    sendNewDataPath: "OrganizationalStructure",
    sendEditDataPath: "OrganizationalStructure",
    tableDataPath: "OrganizationalStructure",
    permission: "archives",
  },
  // Relationship between the departments of various activities
  {
    addName: "AddRelationshipBetweenTheDepartments",
    editName: "EditRelationshipBetweenTheDepartments",
    list: "RelationshipBetweenTheDepartmentsList",
    getFormDataPath: "RelationshipBetweenTheDepartments",
    sendNewDataPath: "RelationshipBetweenTheDepartments",
    sendEditDataPath: "RelationshipBetweenTheDepartments",
    tableDataPath: "RelationshipBetweenTheDepartments",
    permission: "archives",
  },
  // Management Commitment of impartiality
  {
    addName: "AddManagementCommitmentOfImpartiality",
    editName: "EditManagementCommitmentOfImpartiality",
    list: "ManagementCommitmentOfImpartialityList",
    getFormDataPath: "ManagementCommitmentOfImpartiality",
    sendNewDataPath: "ManagementCommitmentOfImpartiality",
    sendEditDataPath: "ManagementCommitmentOfImpartiality",
    tableDataPath: "ManagementCommitmentOfImpartiality",
    permission: "archives",
  },
  // Committee of safeguarding impartiality
  {
    addName: "AddCommitteeOfSafeguardingImpartiality",
    editName: "EditCommitteeOfSafeguardingImpartiality",
    list: "CommitteeOfSafeguardingImpartialityList",
    getFormDataPath: "CommitteeOfSafeguardingImpartiality",
    sendNewDataPath: "CommitteeOfSafeguardingImpartiality",
    sendEditDataPath: "CommitteeOfSafeguardingImpartiality",
    tableDataPath: "CommitteeOfSafeguardingImpartiality",
    permission: "archives",
  },
  // Management Commitment of independence and non-conflict of interests
  {
    addName: "AddManagementCommitmentOfIndependence",
    editName: "EditManagementCommitmentOfIndependence",
    list: "ManagementCommitmentOfIndependenceList",
    getFormDataPath: "ManagementCommitmentOfIndependence",
    sendNewDataPath: "ManagementCommitmentOfIndependence",
    sendEditDataPath: "ManagementCommitmentOfIndependence",
    tableDataPath: "ManagementCommitmentOfIndependence",
    permission: "archives",
  },

  // *** Liability and financing -> (The file has been added to the models part) ***
  // Evidence of  adequate insurance to cover liabilities arising from operations
  {
    addName: "AddLiabilityAndFinancing",
    editName: "EditLiabilityAndFinancing",
    list: "LiabilityAndFinancingList",
    getFormDataPath: "LiabilityAndFinancing",
    sendNewDataPath: "LiabilityAndFinancing",
    sendEditDataPath: "LiabilityAndFinancing",
    tableDataPath: "LiabilityAndFinancing",
    permission: "archives",
  },
  // *** Non-discriminatory conditions ***
  // Commitment of Non- discrimination
  {
    addName: "AddCommitmentOfNonDiscrimination",
    editName: "EditCommitmentOfNonDiscrimination",
    list: "CommitmentOfNonDiscriminationList",
    getFormDataPath: "CommitmentOfNonDiscrimination",
    sendNewDataPath: "CommitmentOfNonDiscrimination",
    sendEditDataPath: "CommitmentOfNonDiscrimination",
    tableDataPath: "CommitmentOfNonDiscrimination",
    permission: "archives",
  },
  //The policy of certification body in dealing with client’s requests and the field of work
  // (C)Certification (B)Body (D)Dealing (C)Client's (R)Requests => AddThePolicyOf[CBDCR]
  {
    addName: "AddThePolicyOfCBDCR",
    editName: "EditThePolicyOfCBDCR",
    list: "ThePolicyOfCBDCRList",
    getFormDataPath: "ThePolicyOfCBDCR",
    sendNewDataPath: "ThePolicyOfCBDCR",
    sendEditDataPath: "ThePolicyOfCBDCR",
    tableDataPath: "ThePolicyOfCBDCR",
    permission: "archives",
  },
  // Risk management procedure
  {
    addName: "AddRiskManagementProcedure",
    editName: "EditRiskManagementProcedure",
    list: "RiskManagementProcedureList",
    getFormDataPath: "RiskManagementProcedure",
    sendNewDataPath: "RiskManagementProcedure",
    sendEditDataPath: "RiskManagementProcedure",
    tableDataPath: "RiskManagementProcedure",
    permission: "archives",
  },
  // Risk evaluation of impartiality
  {
    addName: "AddRiskEvaluationOfImpartiality",
    editName: "EditRiskEvaluationOfImpartiality",
    list: "RiskEvaluationOfImpartialityList",
    getFormDataPath: "RiskEvaluationOfImpartiality",
    sendNewDataPath: "RiskEvaluationOfImpartiality",
    sendEditDataPath: "RiskEvaluationOfImpartiality",
    tableDataPath: "RiskEvaluationOfImpartiality",
    permission: "archives",
  },
  // inform the GSO about rejection products
  {
    addName: "AddInformTheGSOAboutRejectionProducts",
    editName: "EditInformTheGSOAboutRejectionProducts",
    list: "InformTheGSOAboutRejectionProductsList",
    getFormDataPath: "InformTheGSOAboutRejectionProducts",
    sendNewDataPath: "InformTheGSOAboutRejectionProducts",
    sendEditDataPath: "InformTheGSOAboutRejectionProducts",
    tableDataPath: "InformTheGSOAboutRejectionProducts",
    permission: "archives",
  },
  // *** Publicly available information ***
  // The policy of the certification body in dealing with the information
  //  (C)Certification (B)body (D)Dealing => AddThePolicy[CBD]
  {
    addName: "AddThePolicyCBD",
    editName: "EditAddThePolicyCBD",
    list: "ThePolicyCBDList",
    getFormDataPath: "ThePolicyCBD",
    sendNewDataPath: "ThePolicyCBD",
    sendEditDataPath: "ThePolicyCBD",
    tableDataPath: "ThePolicyCBD",
    permission: "archives",
  },
  // Approval by the client to allow the publication of specific information
  {
    addName: "ClientsConsentToPublish",
    editName: "EditClientsConsentToPublish",
    list: "ClientsConsentToPublishList",
    getFormDataPath: "ClientsConsentToPublish",
    sendNewDataPath: "ClientsConsentToPublish",
    sendEditDataPath: "ClientsConsentToPublish",
    tableDataPath: "ClientsConsentToPublish",
    permission: "archives",
  },
  // ***  Legal and contractual matters ***
  // SAITCO Legal entity
  {
    addName: "AddSaitcoLegalEntity",
    editName: "EditSaitcoLegalEntity",
    list: "SaitcoLegalEntityList",
    getFormDataPath: "SaitcoLegalEntity",
    sendNewDataPath: "SaitcoLegalEntity",
    sendEditDataPath: "SaitcoLegalEntity",
    tableDataPath: "SaitcoLegalEntity",
    permission: "archives",
  },
  // Final License of ELE Lab
  {
    addName: "AddFinalLicenseOfELELab",
    editName: "EditFinalLicenseOfELELab",
    list: "FinalLicenseOfELELabList",
    getFormDataPath: "FinalLicenseOfELELab",
    sendNewDataPath: "FinalLicenseOfELELab",
    sendEditDataPath: "FinalLicenseOfELELab",
    tableDataPath: "FinalLicenseOfELELab",
    permission: "archives",
  },
  // Final License of TEX Lab

  {
    addName: "AddFinalLicenseOfTEXLab",
    editName: "EditFinalLicenseOfTEXLab",
    list: "FinalLicenseOfTEXLabList",
    getFormDataPath: "FinalLicenseOfTEXLab",
    sendNewDataPath: "FinalLicenseOfTEXLab",
    sendEditDataPath: "FinalLicenseOfTEXLab",
    tableDataPath: "FinalLicenseOfTEXLab",
    permission: "archives",
  },

  // Final License of GP Lab

  {
    addName: "AddFinalLicenseOfGPLab",
    editName: "EditFinalLicenseOfGPLab",
    list: "FinalLicenseOfGPLabList",
    getFormDataPath: "FinalLicenseOfGPLab",
    sendNewDataPath: "FinalLicenseOfGPLab",
    sendEditDataPath: "FinalLicenseOfGPLab",
    tableDataPath: "FinalLicenseOfGPLab",
    permission: "archives",
  },

  // Primary /Final License of cosmetics Lab

  {
    addName: "AddPrimaryFinalLicenseOfCosmeticsLab",
    editName: "EditPrimaryFinalLicenseOfCosmeticsLab",
    list: "PrimaryFinalLicenseOfCosmeticsLabList",
    getFormDataPath: "PrimaryFinalLicenseOfCosmeticsLab",
    sendNewDataPath: "PrimaryFinalLicenseOfCosmeticsLab",
    sendEditDataPath: "PrimaryFinalLicenseOfCosmeticsLab",
    tableDataPath: "PrimaryFinalLicenseOfCosmeticsLab",
    permission: "archives",
  },

  // Primary Final License of SP Lab

  {
    addName: "AddPrimaryFinalLicenseOfSPLab",
    editName: "EditPrimaryFinalLicenseOfSPLab",
    list: "PrimaryFinalLicenseOfSPLabList",
    getFormDataPath: "PrimaryFinalLicenseOfSPLab",
    sendNewDataPath: "PrimaryFinalLicenseOfSPLab",
    sendEditDataPath: "PrimaryFinalLicenseOfSPLab",
    tableDataPath: "PrimaryFinalLicenseOfSPLab",
    permission: "archives",
  },

  // License of China branch

  {
    addName: "AddLicenseOfChinaBranch",
    editName: "EditLicenseOfChinaBranch",
    list: "LicenseOfChinaBranchList",
    getFormDataPath: "LicenseOfChinaBranch",
    sendNewDataPath: "LicenseOfChinaBranch",
    sendEditDataPath: "LicenseOfChinaBranch",
    tableDataPath: "LicenseOfChinaBranch",
    permission: "archives",
  },

  // *** Responsibility of the different departments ***
  // Departments’ Responsibility of IB
  {
    addName: "AddDepartmentsResponsibilityOfIB",
    editName: "EditDepartmentsResponsibilityOfIB",
    list: "DepartmentsResponsibilityOfIBList",
    getFormDataPath: "DepartmentsResponsibilityOfIB",
    sendNewDataPath: "DepartmentsResponsibilityOfIB",
    sendEditDataPath: "DepartmentsResponsibilityOfIB",
    tableDataPath: "DepartmentsResponsibilityOfIB",
    permission: "archives",
  },

  // Departments’ Responsibility of TB

  {
    addName: "AddDepartmentsResponsibilityOfTB",
    editName: "EditDepartmentsResponsibilityOfTB",
    list: "DepartmentsResponsibilityOfTBList",
    getFormDataPath: "DepartmentsResponsibilityOfTB",
    sendNewDataPath: "DepartmentsResponsibilityOfTB",
    sendEditDataPath: "DepartmentsResponsibilityOfTB",
    tableDataPath: "DepartmentsResponsibilityOfTB",
    permission: "archives",
  },

  // Departments’ Responsibility of CB

  {
    addName: "AddDepartmentsResponsibilityOfCB",
    editName: "EditDepartmentsResponsibilityOfCB",
    list: "DepartmentsResponsibilityOfCBList",
    getFormDataPath: "DepartmentsResponsibilityOfCB",
    sendNewDataPath: "DepartmentsResponsibilityOfCB",
    sendEditDataPath: "DepartmentsResponsibilityOfCB",
    tableDataPath: "DepartmentsResponsibilityOfCB",
    permission: "archives",
  },
  // *** Scopes ***
  // Scope of accreditation of ELE Lab

  {
    addName: "AddScopeOfAccreditationOfELELab",
    editName: "EditScopeOfAccreditationOfELELab",
    list: "ScopeOfAccreditationOfELELabList",
    getFormDataPath: "ScopeOfAccreditationOfELELab",
    sendNewDataPath: "ScopeOfAccreditationOfELELab",
    sendEditDataPath: "ScopeOfAccreditationOfELELab",
    tableDataPath: "ScopeOfAccreditationOfELELab",
    permission: "archives",
  },

  // Scope of accreditation of TEX Lab

  {
    addName: "AddScopeOfAccreditationOfTEXLab",
    editName: "EditScopeOfAccreditationOfTEXLab",
    list: "ScopeOfAccreditationOfTEXLabList",
    getFormDataPath: "ScopeOfAccreditationOfTEXLab",
    sendNewDataPath: "ScopeOfAccreditationOfTEXLab",
    sendEditDataPath: "ScopeOfAccreditationOfTEXLab",
    tableDataPath: "ScopeOfAccreditationOfTEXLab",
    permission: "archives",
  },

  // Scope of accreditation of GP Lab
  {
    addName: "AddScopeOfAccreditationOfGPLab",
    editName: "EditScopeOfAccreditationOfGPLab",
    list: "ScopeOfAccreditationOfGPLabList",
    getFormDataPath: "ScopeOfAccreditationOfGPLab",
    sendNewDataPath: "ScopeOfAccreditationOfGPLab",
    sendEditDataPath: "ScopeOfAccreditationOfGPLab",
    tableDataPath: "ScopeOfAccreditationOfGPLab",
    permission: "archives",
  },

  // Scope of accreditation Certificate of cosmetics Lab
  {
    addName: "AddScopeOfAccreditationCertificateOfCosmeticsLab",
    editName: "EditScopeOfAccreditationCertificateOfCosmeticsLab",
    list: "ScopeOfAccreditationCertificateOfCosmeticsLabList",
    getFormDataPath: "ScopeOfAccreditationCertificateOfCosmeticsLab",
    sendNewDataPath: "ScopeOfAccreditationCertificateOfCosmeticsLab",
    sendEditDataPath: "ScopeOfAccreditationCertificateOfCosmeticsLab",
    tableDataPath: "ScopeOfAccreditationCertificateOfCosmeticsLab",
    permission: "archives",
  },

  // Scope of accreditation Certificate of SP Lab
  {
    addName: "AddScopeOfAccreditationCertificateOfSPLab",
    editName: "EditScopeOfAccreditationCertificateOfSPLab",
    list: "ScopeOfAccreditationCertificateOfSPLabList",
    getFormDataPath: "ScopeOfAccreditationCertificateOfSPLab",
    sendNewDataPath: "ScopeOfAccreditationCertificateOfSPLab",
    sendEditDataPath: "ScopeOfAccreditationCertificateOfSPLab",
    tableDataPath: "ScopeOfAccreditationCertificateOfSPLab",
    permission: "archives",
  },

  // Scope of accreditation of IB

  {
    addName: "AddScopeOfAccreditationOfIB",
    editName: "EditScopeOfAccreditationOfIB",
    list: "ScopeOfAccreditationOfIBList",
    getFormDataPath: "ScopeOfAccreditationOfIB",
    sendNewDataPath: "ScopeOfAccreditationOfIB",
    sendEditDataPath: "ScopeOfAccreditationOfIB",
    tableDataPath: "ScopeOfAccreditationOfIB",
    permission: "archives",
  },

  // Scope of accreditation of CB

  {
    addName: "AddScopeOfAccreditationOfCB",
    editName: "EditScopeOfAccreditationOfCB",
    list: "ScopeOfAccreditationOfCBList",
    getFormDataPath: "ScopeOfAccreditationOfCB",
    sendNewDataPath: "ScopeOfAccreditationOfCB",
    sendEditDataPath: "ScopeOfAccreditationOfCB",
    tableDataPath: "ScopeOfAccreditationOfCB",
    permission: "archives",
  },

  // *** Organizational structure and senior management ***
  // Organizational structure of IB
  {
    addName: "AddOrganizationalStructureOfIB",
    editName: "EditOrganizationalStructureOfIB",
    list: "OrganizationalStructureOfIBList",
    getFormDataPath: "OrganizationalStructureOfIB",
    sendNewDataPath: "OrganizationalStructureOfIB",
    sendEditDataPath: "OrganizationalStructureOfIB",
    tableDataPath: "OrganizationalStructureOfIB",
    permission: "archives",
  },

  // Organizational structure of labs
  {
    addName: "AddOrganizationalStructureOfLabs",
    editName: "EditOrganizationalStructureOfLabs",
    list: "OrganizationalStructureOfLabsList",
    getFormDataPath: "OrganizationalStructureOfLabs",
    sendNewDataPath: "OrganizationalStructureOfLabs",
    sendEditDataPath: "OrganizationalStructureOfLabs",
    tableDataPath: "OrganizationalStructureOfLabs",
    permission: "archives",
  },

  // Organizational structure of  CB
  {
    addName: "AddOrganizationalStructureOfCB",
    editName: "EditOrganizationalStructureOfCB",
    list: "OrganizationalStructureOfCBList",
    getFormDataPath: "OrganizationalStructureOfCB",
    sendNewDataPath: "OrganizationalStructureOfCB",
    sendEditDataPath: "OrganizationalStructureOfCB",
    tableDataPath: "OrganizationalStructureOfCB",
    permission: "archives",
  },

  // *** Mechanism for safeguarding impartiality ***
  // Control of Conformity Marking
  {
    addName: "AddControlOfConformityMarking",
    editName: "EditControlOfConformityMarking",
    list: "ControlOfConformityMarkingList",
    getFormDataPath: "ControlOfConformityMarking",
    sendNewDataPath: "ControlOfConformityMarking",
    sendEditDataPath: "ControlOfConformityMarking",
    tableDataPath: "ControlOfConformityMarking",
    permission: "archives",
  },

  // *** Equipment ***
  // Work Instruction
  {
    addName: "AddWorkInstruction",
    editName: "EditWorkInstruction",
    list: "WorkInstructionList",
    getFormDataPath: "WorkInstruction",
    sendNewDataPath: "WorkInstruction",
    sendEditDataPath: "WorkInstruction",
    tableDataPath: "WorkInstruction",
    permission: "archives",
  },

  // equipment in the lab
  {
    addName: "AddEquipmentInTheLab",
    editName: "EditEquipmentInTheLab",
    list: "EquipmentInTheLabList",
    getFormDataPath: "EquipmentInTheLab",
    sendNewDataPath: "EquipmentInTheLab",
    sendEditDataPath: "EquipmentInTheLab",
    tableDataPath: "EquipmentInTheLab",
    permission: "archives",
  },
  // Procedure of Expand Calibration Certificate Period
  {
    addName: "AddProcedureOfExpandCalibrationCertificatePeriod",
    editName: "EditProcedureOfExpandCalibrationCertificatePeriod",
    list: "ProcedureOfExpandCalibrationCertificatePeriodList",
    getFormDataPath: "ProcedureOfExpandCalibrationCertificatePeriod",
    sendNewDataPath: "ProcedureOfExpandCalibrationCertificatePeriod",
    sendEditDataPath: "ProcedureOfExpandCalibrationCertificatePeriod",
    tableDataPath: "ProcedureOfExpandCalibrationCertificatePeriod",
    permission: "archives",
  },
  // Evaluates & Reviews the validity of Calibration Certificate and Capability of Expand the Period (Automatic adjustment method)
  {
    addName: "AddEvaluatesAndReviewsTheValidityOfCalibrationCertificate",
    editName: "EditEvaluatesAndReviewsTheValidityOfCalibrationCertificate",
    list: "EvaluatesAndReviewsTheValidityOfCalibrationCertificateList",
    getFormDataPath: "EvaluatesAndReviewsTheValidityOfCalibrationCertificate",
    sendNewDataPath: "EvaluatesAndReviewsTheValidityOfCalibrationCertificate",
    sendEditDataPath: "EvaluatesAndReviewsTheValidityOfCalibrationCertificate",
    tableDataPath: "EvaluatesAndReviewsTheValidityOfCalibrationCertificate",
    permission: "archives",
  },

  // *** Confidentiality ***
  // Confidentiality and  impartiality Commitment
  {
    addName: "AddConfidentialityAndImpartialityCommitment",
    editName: "EditConfidentialityAndImpartialityCommitment",
    list: "ConfidentialityAndImpartialityCommitmentList",
    getFormDataPath: "ConfidentialityAndImpartialityCommitment",
    sendNewDataPath: "ConfidentialityAndImpartialityCommitment",
    sendEditDataPath: "ConfidentialityAndImpartialityCommitment",
    tableDataPath: "ConfidentialityAndImpartialityCommitment",
    permission: "archives",
  },
  // Release confidential information
  {
    addName: "AddReleaseConfidentialInformation",
    editName: "EditReleaseConfidentialInformation",
    list: "ReleaseConfidentialInformationList",
    getFormDataPath: "ReleaseConfidentialInformation",
    sendNewDataPath: "ReleaseConfidentialInformation",
    sendEditDataPath: "ReleaseConfidentialInformation",
    tableDataPath: "ReleaseConfidentialInformation",
    permission: "archives",
  },
  // ***  Quality policy and objectives ***
  // Quality policy and objectives
  {
    addName: "AddQualityPolicyAndObjectives",
    editName: "EditQualityPolicyAndObjectives",
    list: "QualityPolicyAndObjectivesList",
    getFormDataPath: "QualityPolicyAndObjectives",
    sendNewDataPath: "QualityPolicyAndObjectives",
    sendEditDataPath: "QualityPolicyAndObjectives",
    tableDataPath: "QualityPolicyAndObjectives",
    permission: "archives",
  },
  // *** Mechanism for safeguarding impartiality ***
  // record of impartiality committee  personal
  {
    addName: "AddRecordOfImpartialityCommitteePersonal",
    editName: "EditRecordOfImpartialityCommitteePersonal",
    list: "RecordOfImpartialityCommitteePersonalList",
    getFormDataPath: "RecordOfImpartialityCommitteePersonal",
    sendNewDataPath: "RecordOfImpartialityCommitteePersonal",
    sendEditDataPath: "RecordOfImpartialityCommitteePersonal",
    tableDataPath: "RecordOfImpartialityCommitteePersonal",
    permission: "archives",
  },
  // Plan of impartiality committee meeting
  {
    addName: "AddRecordOfImpartialityCommitteeMeeting",
    editName: "EditRecordOfImpartialityCommitteeMeeting",
    list: "RecordOfImpartialityCommitteeMeetingList",
    getFormDataPath: "RecordOfImpartialityCommitteeMeeting",
    sendNewDataPath: "RecordOfImpartialityCommitteeMeeting",
    sendEditDataPath: "RecordOfImpartialityCommitteeMeeting",
    tableDataPath: "RecordOfImpartialityCommitteeMeeting",
    permission: "archives",
  },
  // impartiality committee invitation
  {
    addName: "AddImpartialityCommitteeInvitation",
    editName: "EditImpartialityCommitteeInvitation",
    list: "ImpartialityCommitteeInvitationList",
    getFormDataPath: "ImpartialityCommitteeInvitation",
    sendNewDataPath: "ImpartialityCommitteeInvitation",
    sendEditDataPath: "ImpartialityCommitteeInvitation",
    tableDataPath: "ImpartialityCommitteeInvitation",
    permission: "archives",
  },

  // Report  of impartiality committee meeting
  {
    addName: "AddReportOfImpartialityCommitteeMeeting",
    editName: "EditReportOfImpartialityCommitteeMeeting",
    list: "ReportOfImpartialityCommitteeMeetingList",
    getFormDataPath: "ReportOfImpartialityCommitteeMeeting",
    sendNewDataPath: "ReportOfImpartialityCommitteeMeeting",
    sendEditDataPath: "ReportOfImpartialityCommitteeMeeting",
    tableDataPath: "ReportOfImpartialityCommitteeMeeting",
    permission: "archives",
  },
  // Certification Request Agreement
  {
    addName: "AddCertificationRequestAgreement",
    editName: "EditCertificationRequestAgreement",
    list: "CertificationRequestAgreementList",
    getFormDataPath: "CertificationRequestAgreement",
    sendNewDataPath: "CertificationRequestAgreement",
    sendEditDataPath: "CertificationRequestAgreement",
    tableDataPath: "CertificationRequestAgreement",
    permission: "archives",
  },

  // *** Personnel ***
  // Job Description
  {
    addName: "AddJobDescription",
    editName: "EditJobDescription",
    list: "JobDescriptionList",
    getFormDataPath: "JobDescription",
    sendNewDataPath: "JobDescription",
    sendEditDataPath: "JobDescription",
    tableDataPath: "JobDescription",
    permission: "archives",
  },

  // Job Description for Lab’s
  {
    addName: "AddJobDescriptionForLabs",
    editName: "EditJobDescriptionForLabs",
    list: "JobDescriptionForLabsList",
    getFormDataPath: "JobDescriptionForLabs",
    sendNewDataPath: "JobDescriptionForLabs",
    sendEditDataPath: "JobDescriptionForLabs",
    tableDataPath: "JobDescriptionForLabs",
    permission: "archives",
  },
  // Assignment
  {
    addName: "AddAssignment",
    editName: "EditAssignment",
    list: "AssignmentList",
    getFormDataPath: "Assignment",
    sendNewDataPath: "Assignment",
    sendEditDataPath: "Assignment",
    tableDataPath: "Assignment",
    permission: "archives",
  },
  // Efficiency Matrix
  {
    addName: "AddEfficiencyMatrix",
    editName: "EditEfficiencyMatrix",
    list: "EfficiencyMatrixList",
    getFormDataPath: "EfficiencyMatrix",
    sendNewDataPath: "EfficiencyMatrix",
    sendEditDataPath: "EfficiencyMatrix",
    tableDataPath: "EfficiencyMatrix",
    permission: "archives",
  },
  // Application form for employment/  training
  {
    addName: "AddApplicationFormForEmploymentAndTraining",
    editName: "EditApplicationFormForEmploymentAndTraining",
    list: "ApplicationFormForEmploymentAndTrainingList",
    getFormDataPath: "ApplicationFormForEmploymentAndTraining",
    sendNewDataPath: "ApplicationFormForEmploymentAndTraining",
    sendEditDataPath: "ApplicationFormForEmploymentAndTraining",
    tableDataPath: "ApplicationFormForEmploymentAndTraining",
    permission: "archives",
  },
  // *** Facilities and environmental conditions ***
  // Authorized Person  in Assessment Body
  {
    addName: "AddAuthorizedPersonInAssessmentBody",
    editName: "EditAuthorizedPersonInAssessmentBody",
    list: "AuthorizedPersonInAssessmentBodyList",
    getFormDataPath: "AuthorizedPersonInAssessmentBody",
    sendNewDataPath: "AuthorizedPersonInAssessmentBody",
    sendEditDataPath: "AuthorizedPersonInAssessmentBody",
    tableDataPath: "AuthorizedPersonInAssessmentBody",
    permission: "archives",
  },

  // *** Selection, verification and validation of methods ***
  // Acceptance and Rejection Criteria
  {
    addName: "AddAcceptanceAndRejectionCriteria",
    editName: "EditAcceptanceAndRejectionCriteria",
    list: "AcceptanceAndRejectionCriteriaList",
    getFormDataPath: "AcceptanceAndRejectionCriteria",
    sendNewDataPath: "AcceptanceAndRejectionCriteria",
    sendEditDataPath: "AcceptanceAndRejectionCriteria",
    tableDataPath: "AcceptanceAndRejectionCriteria",
    permission: "archives",
  },
  // *** Samples ***
  // Procedure of Sampling
  {
    addName: "AddProcedureOfSampling",
    editName: "EditProcedureOfSampling",
    list: "ProcedureOfSamplingList",
    getFormDataPath: "ProcedureOfSampling",
    sendNewDataPath: "ProcedureOfSampling",
    sendEditDataPath: "ProcedureOfSampling",
    tableDataPath: "ProcedureOfSampling",
    permission: "archives",
  },
  // sample layout of reception, handling, storage & disposal
  {
    addName: "AddSampleLayoutOfReception",
    editName: "EditSampleLayoutOfReception",
    list: "SampleLayoutOfReceptionList",
    getFormDataPath: "SampleLayoutOfReception",
    sendNewDataPath: "SampleLayoutOfReception",
    sendEditDataPath: "SampleLayoutOfReception",
    tableDataPath: "SampleLayoutOfReception",
    permission: "archives",
  },
  // *** Technical records ***
  // Dealing with Technical Records
  {
    addName: "AddDealingWithTechnicalRecords",
    editName: "EditDealingWithTechnicalRecords",
    list: "DealingWithTechnicalRecordsList",
    getFormDataPath: "DealingWithTechnicalRecords",
    sendNewDataPath: "DealingWithTechnicalRecords",
    sendEditDataPath: "DealingWithTechnicalRecords",
    tableDataPath: "DealingWithTechnicalRecords",
    permission: "archives",
  },
  // *** Ensuring the validity of results ***
  // Inter lab. comparaison Protocol  -ILC Protocol
  {
    addName: "AddInterLabComparaisonProtocolILC",
    editName: "EditInterLabComparaisonProtocolILC",
    list: "InterLabComparaisonProtocolILCList",
    getFormDataPath: "InterLabComparaisonProtocolILC",
    sendNewDataPath: "InterLabComparaisonProtocolILC",
    sendEditDataPath: "InterLabComparaisonProtocolILC",
    tableDataPath: "InterLabComparaisonProtocolILC",
    permission: "archives",
  },
  // Verification procedure
  {
    addName: "AddVerificationProcedure",
    editName: "EditVerificationProcedure",
    list: "VerificationProcedureList",
    getFormDataPath: "VerificationProcedure",
    sendNewDataPath: "VerificationProcedure",
    sendEditDataPath: "VerificationProcedure",
    tableDataPath: "VerificationProcedure",
    permission: "archives",
  },
  // Insurance of tests with control charts
  {
    addName: "AddInsuranceOfTestsWithControlCharts",
    editName: "EditInsuranceOfTestsWithControlCharts",
    list: "InsuranceOfTestsWithControlChartsList",
    getFormDataPath: "InsuranceOfTestsWithControlCharts",
    sendNewDataPath: "InsuranceOfTestsWithControlCharts",
    sendEditDataPath: "InsuranceOfTestsWithControlCharts",
    tableDataPath: "InsuranceOfTestsWithControlCharts",
    permission: "archives",
  },
  // *** Reporting of results ***
  // Instruction of using  AB Logo
  {
    addName: "AddInstructionOfUsingABLogo",
    editName: "EditInstructionOfUsingABLogo",
    list: "InstructionOfUsingABLogoList",
    getFormDataPath: "InstructionOfUsingABLogo",
    sendNewDataPath: "InstructionOfUsingABLogo",
    sendEditDataPath: "InstructionOfUsingABLogo",
    tableDataPath: "InstructionOfUsingABLogo",
    permission: "archives",
  },
];
